import ky from "ky";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

interface Hotel {
  Id: number;
  Name: string;
}
class MessagesAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewChannel(
    ChannelIcon: File | null,
    name: string,
    UserIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("Title", name);
    formData.append("NetworkUnitId", "1");
    formData.append("Active", "true");
    UserIds.forEach((id) => formData.append("UserIds[]", id));

    if (File && ChannelIcon) {
      formData.append("ChannelIcon", ChannelIcon);
    }

    try {
      const headers = await this.getHeaders();
      const data = await ky.post(`${API_URL}chat-topic`, {
        body: formData,
        headers,
      });
      return data;
    } catch (error) {
      
      throw error;
    }
  }

  public async listNumberOfChannels(): Promise<undefined> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}chat-topic-user/1`,
        { headers }
      );
      return response.json();
    } catch (error) {
      
      return undefined;
    }
  }

  public async listChannels(): Promise<undefined> {
    const headers = await this.getHeaders("application/json");
  
    try {
      const response = await ky.get(
        `${API_URL}chat-topic/list-channel/488/1`,
        { headers }
      );
      return response.json();
    } catch (error) {
      
      return undefined;
    }
  }
  public async getMessagesChat(
    ChatTopicId: number,
    Title: string
  ): Promise<undefined> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}chat/${ChatTopicId}/${Title}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      
      return undefined;
    }
  }

  public async getMessagesChatPaginated(
    ChatThreads: number,
    PageNumber: string
  ): Promise<any> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}chat/paginated/${ChatThreads}/${PageNumber}`,
        { headers }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      
      return undefined;
    }
  }

  public async getResponsibleUsers(): Promise<undefined> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}admin/units/1`,
        { headers }
      );
      return response.json();
    } catch (error) {
      
      return undefined;
    }
  }

  public async deleteChannel(id: number): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `${API_URL}chat-topic-user/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      
    }
  }

  public async listChatsTopics(): Promise<undefined> {
    const headers = await this.getHeaders("application/json");
    const userID = localStorage.getItem("userId");
    const storedNetworks = localStorage.getItem("networks");
    let parsedNetworks: Hotel[] = [];

    if (storedNetworks) {
      parsedNetworks = JSON.parse(storedNetworks);
    }

    try {
      const response = await ky.get(
        `${API_URL}chat-topic/admin-chanel/${userID}/${parsedNetworks[0].Id}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      
      return undefined;
    }
  }

  public async sendMessage(
    chatThreadsId: number,
    message: string,
    userId: number,
    chatTopicId: number,
    file?: File
  ): Promise<any> {
    const formData = new FormData();
    formData.append("ChatThreadsId", chatThreadsId.toString());
    formData.append("UserId", userId.toString());
    formData.append("ChatTopicId", chatTopicId.toString());
    formData.append("Message", message);

    if (file) {
      formData.append("File", file);
    }

    try {

      const headers = await this.getHeaders();
      const response = await ky.post(
        `${API_URL}chat/admin`,

        {
          headers,
          body: formData,
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      
      throw error;
    }
  }

  public async listAdminChannels(networkUnitId: number, userId: number, active?: boolean): Promise<any> {
    const headers = await this.getHeaders("application/json");

    const url = new URL(`${API_URL}chat-thread/admin`);
    const params = new URLSearchParams();

    if (networkUnitId) params.append("NetworkUnitId", networkUnitId.toString());
    if (userId) params.append("UserId", userId.toString());
    if (active !== undefined) params.append("Active", active.toString());

    url.search = params.toString();


    try {
      const response = await ky.get(
        url.toString(),
        { headers }
      );
  
      const data = (await response.json()) as any[];
   
      const ids = data.map((conv: any) => conv.Id);
      const uniqueIds = new Set(ids);
      if (uniqueIds.size !== ids.length) {
        console.warn("A API retornou conversas duplicadas:", ids);
      }
    
      return data;
    } catch (error) {
      
      throw error;
    }
  }

  public async getPaginatedMessages(
    chatThreadsId: number,
    offset: number
  ): Promise<any> {

    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}chat/paginated/${chatThreadsId}/${offset}`,
        { headers }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      
      throw error;
    }
  }

  public async postMessage(
    email: string,
    password: string
  ): Promise<undefined> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.post(
        `${API_URL}auth/admin`,
        { json: { email, password }, headers }
      );
      return response.json();
    } catch (error: any) {
      if (error.response.statusText === "Unauthorized") {
        try {
          const response = await ky.post(
            `${API_URL}auth/investor`,

            { json: { email, password }, headers }
          );
          return response.json();
        } catch (investorError) {
          console.error(
            "Erro ao realizar login como investidor",
            investorError
          );
          throw investorError;
        }
      }
      
      throw error;
    }
  }
}

export default new MessagesAPI();