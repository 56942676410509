import { z } from "zod";
import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import IconSelector from "../../../components/IconSelector";
import Input from "../../../components/Input";
import MultiSelect from "../../../components/MultiSelect";
import Button from "../../../components/Button";
import UploadContract from "../../../components/Upload";
import BreadcrumbsPage from "../../../components/Breadcrumbs";
import FileApi from "../../../api/file/file";
import apiCategory from "../../../api/category/apiCategory";
import { ResponseUsers } from "./types";
import "react-toastify/dist/ReactToastify.css";
import "../../../index.css";
import icon1 from "../../../assets/icons/main-icon.svg";

type Category = {
  Id: number;
  Name: string;
};

export default function NewFile() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;

  const [users, setUsers] = useState<ResponseUsers[]>([]);
  const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  const newChannelCreateSchema = z.object({
    name: z.string().min(1, "Nome é obrigatório"),
    responsibleUsers: z.any(),
    file: z
      .any()
      .refine((file) => file instanceof File, {
        message: "Arquivo é obrigatório",
      })
      .refine((file) => file?.type === "application/pdf", {
        message: "Apenas arquivos PDF são permitidos",
      }),
    category: z.string().min(1, "Categoria é obrigatória"),
    responsibleTypeUsers: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
        })
      )
      .nonempty("Campo de responsáveis é obrigatório")
      .optional(),
  });
  

  const listUsersResponsible = [
    { value: "todos", label: "Todos os Proprietários" },
    { value: "dentroPool", label: "Proprietários dentro do pool" },
    { value: "foraPool", label: "Proprietários fora do pool" },
  ];

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      name: "",
      responsibleUsers: "",
      file: undefined,
      category: "",
      responsibleTypeUsers: isEdit ? undefined : [],
    },
  });

  async function listCategories() {
    try {
      const response = await apiCategory.getListCategory();
      setCategories(response as Category[]);
      return response;
    } catch (error) {
      console.error("Erro ao listar categorias", error);
    }
  }

  useEffect(() => {
    async function fetchDefaultIcon() {
      try {
        const response = await fetch(icon1);
        const blob = await response.blob();
        const defaultFile = new File([blob], "icon-default.png", {
          type: blob.type,
        });
        setSelectedIconFile(defaultFile);
      } catch (error) {
        console.error("Erro ao carregar ícone padrão", error);
      }
    }

    if (!selectedIconFile) {
      fetchDefaultIcon();
    }
  }, [selectedIconFile]);

  async function handleSubmitForm(data: NewChannelsSchema) {
    let poolType = "1";

    if (
      Array.isArray(data.responsibleTypeUsers) &&
      data.responsibleTypeUsers.length > 0
    ) {
      const selectedType = data.responsibleTypeUsers[0].value;

      switch (selectedType) {
        case "todos":
          poolType = "1";
          break;
        case "dentroPool":
          poolType = "2";
          break;
        case "foraPool":
          poolType = "3";
          break;
        default:
          console.warn("Tipo de responsável inválido:", selectedType);
          poolType = "1";
      }
    }

    const categoryId = String(data.category);

    const categoryIdString = data.category.toString();
    const categoryIdNumber = Number(data.category);

    try {
      if (isEdit) {
        const id = "1";
        const categoryIdNumber = Number(data.category);
        await FileApi.editFile(
          dataEdit.Id,
          selectedIconFile,
          data.name,
          categoryIdNumber, 
          data.file ? [data.file] : []
        );

        navigate(-1);
      } else {
        let fileType = "";
        if (data.responsibleTypeUsers?.length) {
          const val = data.responsibleTypeUsers[0].value;
          if (val === "todos") fileType = "1";
          else if (val === "dentroPool") fileType = "2";
          else if (val === "foraPool") fileType = "3";
        }

        const categoryId = parseInt(data.category, 10);

        await FileApi.postNewFile(
          selectedIconFile,
          data.file ? [data.file] : [],
          data.name,
          poolType,
          categoryIdString
        );
        console.log("Arquivo cadastrado com sucesso!");
        navigate(-1);
      }
    } catch (error) {
      console.error("Erro ao cadastrar/editar arquivo", error);
    }
  }

  async function listUser() {
    const response = await FileApi.listUsers();
    if (response) setUsers(response);
  }

  useEffect(() => {
    listUser();
    listCategories();
  }, []);

  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit.Name);
      setValue("category", dataEdit.CategoryId?.toString() || "");
    }
  }, [isEdit, dataEdit, setValue]);

  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div>
        <BreadcrumbsPage />
        <Header
          title={isEdit ? "Editar documentos" : "Cadastrar documentos"}
          arrowIcon={true}
        />

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          <form
            id="my-form"
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            {!isEdit && (
              <div className="flex flex-col gap-4 rounded-lg">
                <Controller
                  name="responsibleTypeUsers"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      label="Disponibilizar para"
                      data={listUsersResponsible}
                      onChange={(values: SetStateAction<never[]>) => {
                        field.onChange(values);
                      }}
                      style={{ width: "100%" }}
                      register={field}
                      errors={errors.responsibleTypeUsers}
                      message="Campo obrigatório"
                      file={false}
                      keyField="value"
                      labelField="label"
                    />
                  )}
                />
              </div>
            )}

            <div className="flex gap-4">
              <IconSelector
                onIconSelect={(icon: React.ReactNode) => {
                  if (React.isValidElement(icon) && icon.props?.src) {
                    fetch(icon.props.src)
                      .then((response) => response.blob())
                      .then((blob) => {
                        const file = new File([blob], "icon.png", {
                          type: blob.type,
                        });
                        setSelectedIconFile(file);
                      })
                      .catch((error) => {
                        console.error("Erro ao buscar o ícone", error);
                      });
                  } else {
                    console.error("Ícone selecionado inválido.");
                  }
                }}
              />

              <Input
                label="Nome"
                type="text"
                placeholder="Digite o nome do documento"
                register={register("name")}
                errors={errors.name}
              />

              <div className="flex flex-col w-full">
                <label className="text-[#404040] font-bold text-base">
                  Categoria
                </label>
                <select
                  {...register("category")}
                  className="mt-1 rounded p-2 border border-[#E3E7FF] w-full h-10 bg-white"
                >
                  <option value="">Selecione uma categoria</option>
                  {categories.map((category) => (
                    <option key={category.Id} value={category.Id}>
                      {category.Name}
                    </option>
                  ))}
                </select>
                {errors.category && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.category.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col font-main w-full">
              <p className="font-medium text-[#404040]">Arquivo</p>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadContract
                    name="file"
                    onUpload={(files) => {
                      if (files.length > 0) {
                        field.onChange(files[0]);
                      } else {
                        field.onChange(undefined);
                      }
                    }}
                    hasFile={!!field.value}
                    error={errors.file}
                  />
                )}
              />
              {errors.file?.message && (
                <p className="text-red-500 text-sm mt-1">
                  {String(errors.file.message)}
                </p>
              )}

              <p style={{ color: "#6D7882", fontSize: "14px" }}>
                Tamanho máximo 10Mb
              </p>
            </div>
          </form>
        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10">
        <Button
          title="Voltar"
          onClick={() => navigate(-1)}
          backGroundColor="#FCFCFA"
          color="#F28E00"
          borderRadius={8}
        />
        <Button
          title={isEdit ? "Salvar arquivo" : "Cadastrar arquivo"}
          backGroundColor="#F28E00"
          color="#FAFAFC"
          borderRadius={8}
          type="submit"
          form="my-form"
        />
      </div>
    </div>
  );
}
